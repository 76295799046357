import React from 'react';

const slides = [
    {
        image: "/images/cards/1.webp",
        title: "Tecnología increíble <span class='color-yellow'>a precios accesibles</span>",
        description: "<p>Diseñamos experiencias que convierten a los visitantes en clientes, guiando a los usuarios a realizar acciones como hacer una compra, descargar contenido o registrarse.</p> <p>Gracias a nuestra ubicación en Colombia, ofrecemos precios competitivos y tecnología de alta calidad, aprovechando el valor del USD para ofrecer resultados de primer nivel a una fracción del costo.</p>"
    },
    {
        image: "/images/cards/5.webp",
        class: "highlighted",
        title: "Creamos productos digitales <span class='color-yellow'>rápido y fácil con IA</span>",
        description: "<p>Co-desarrollamos cada sitio web con Inteligencia Artificial (IA) para una mayor calidad y disponibilidad de recursos.</p> <p>Si carece de fotos por ejemplo, usaremos Midjourney para crearlas, y si necesita más texto y contenidos, los generamos con ChatGPT Plus (ChatGPT 4).</p><p> Esto asegura que diseñemos una poderosa herramienta de ventas para su negocio a un precio asequible.</p>"
    },
    {
        image: "/images/cards/2.webp",
        title: "Enfocados de principio a fin en el <span class='color-yellow'>ROI</span>",
        description: "<p>Deje de invertir en estrategias ineficaces. Una Landing Page le permite rastrear y analizar con precisión el comportamiento de sus visitantes, brindándole la oportunidad de identificar lo que realmente funciona.</p><p>Con esta valiosa información, podrá optimizar su página y alcanzar mejores resultados, maximizando el retorno de su inversión (ROI).</p>"
    },
    {
        image: "/images/cards/3.webp",
        title: "Llevando la voz y el tono de su marca o empresa <span class='color-yellow'>a Internet</span>",
        description: "<p>Con un sitio web diseñado específicamente para usted, crearemos una experiencia memorable y alineada con su marca, desde un diseño atractivo hasta un mensaje claro y conciso.</p><p>Resaltaremos las ventajas únicas de su producto o servicio, diferenciándolo de la competencia y dejando una impresión duradera en sus visitantes.</p>"
    },
    {
        image: "/images/cards/4.webp",
        title: "Datos relevantes de tus visitantes <span class='color-yellow'>para tomar decisiones estratégicas</span>",
        description: "<p>Un sitio web diseñado específicamente es una poderosa herramienta para recopilar información valiosa sobre sus visitantes, capturando sus intereses, necesidades y preferencias mediante formularios estratégicos, herramientas analíticas y datos demográficos.</p><p>Este conocimiento profundo facilita la toma de decisiones estratégicas en marketing y ventas, permitiendo una personalización efectiva y una mejor orientación hacia el éxito comercial.</p>"
    },
];

const InfoCards = () => {
    return (
        <div className="info-cards">
            {slides.map((slide, index) => (
                <article key={index} className={`info-card ${slide.class || ''} slide-content ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    <figure
                        className="info-card__figure info-card__image"
                        style={{
                            backgroundImage: `url(${slide.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                        aria-hidden="true"
                    />
                    <div className="info-card__content d-flex flex-column">
                        <h3 dangerouslySetInnerHTML={{ __html: slide.title }} />
                        <span dangerouslySetInnerHTML={{ __html: slide.description }} />
                    </div>
                </article>
            ))}
        </div>
    );
};

export default InfoCards;