import React from 'react'
import './Testimonials.css'

import image1 from './images/0_2.png'
import image2 from './images/2-4.png'
import image3 from './images/image-7.jpg'

const testimonials = [
    {
        name: 'Andrés Rodríguez',
        position: 'Marketing Director, Valley Medical Suppliers LLC 🇺🇸',
        quote: 'Working with VPixel CO was like watching coding ninjas dance on a keyboard. They tackled our complex software with gusto, weaving a symphony of code that was both robust and elegant. And the UI? Forget beautiful, it was mind-blowing. They didn’t just design it, they crafted an experience.',
        image: image1
    },
    {
        name: 'Sandra Batista',
        position: 'Owner, Accesorios Bati 🇪🇸',
        quote: 'Before VPixel CO, my virtual store was a mess. Customers were lost and confused, and my sales were suffering. When we partnership with VPixel CO they transformed my store into a user-friendly haven, a place where people could actually find what they were looking for and enjoy the shopping journey. I’m finally proud to call my virtual store my own.',
        image: image2
    },
    {
        name: 'Carolina Sarmiento',
        position: 'Owner, Caro Store 🇨🇴',
        quote: 'VPixel CO are like the landing page ninjas we never knew we needed. They’re fast, they’re flexible, and they get our (sometimes crazy) demands. Plus, the landing pages they cooked up? Pure fire. Leads are flooding our CRM, and it’s all thanks to these awesome dudes.',
        image: image3
    },

]

const Testimonials = () => {
    return (
        <>

            <section className="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="testimonials__container">
                                {testimonials.map((testimonial, index) => (
                                    <div key={index} className="testimonial d-flex flex-column">

                                        <div className="testimonial__content">
                                            <p className="testimonial__quote">{testimonial.quote}</p>
                                        </div>

                                        <div className="testimonial__image d-flex align-items-center">
                                            <img src={testimonial.image} alt={testimonial.name} />
                                            <div>
                                                <p className="testimonial__name">{testimonial.name}</p>
                                                <p className="testimonial__position">{testimonial.position}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Testimonials     