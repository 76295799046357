import React from 'react';
import constants from '../../constants';
const Pricing = ({ discountValue }) => {
    return (
        <>


            <section className="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h2 className="title-gradient">
                                    Obtenga hoy su Landing Page con un <span className="color-yellow">{discountValue}% de descuento</span>  <img className="person-img" src="/images/people/girl-2.webp" alt="" />
                                </h2>
                                <p>
                                    Esta es su oportunidad de tener un sitio web que brinde información, analice y capture potenciales clientes para su negocio las 24 horas, los 7 días de la semana, a un precio muy especial por tiempo limitado.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="price d-flex flex-column align-items-center">
                                <span className="old">$879.200 COP</span>
                                <span className="new">$791.280 COP</span>
                                <span className="usd">$190.65 USD</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table">
                                <ul>
                                    <li className='h'>⭐ Hosting especializado y Dominio por 1 año</li>
                                    <li className='h'>⭐ Entregas entre 5 y 12 días</li>
                                    <li className='h'>⭐ Diseño visual profesional (Sin plantillas)</li>
                                    <li>UX especializada para convertir</li>
                                    <li>Plataforma de administración completa</li>
                                    <li className='h'>⭐ Copywriting mejorado con IA ChatGPT Plus</li>
                                    <li className='h'>⭐ Imágenes mejoradas con IA Midjourney</li>
                                    <li>Formulario de captura de Leads</li>
                                    <li>Integración con Mailchimp</li>
                                    <li>Google Analytics</li>
                                    <li>Google Maps</li>
                                </ul>
                            </div>

                            <div className="pricing__footer">
                                <a target='_blank' rel='noopener noreferrer' href={constants.site.contactLink} data-service="landing" className="button">¡Solicite su cotización hoy!</a>
                                <div className="payments">
                                    <img src="/images/landing/visa52d765334f273be7.webp" alt="" />
                                    <img src="/images/landing/amex8c702aae174e8d5b.webp" alt="" />
                                    <img src="/images/landing/mastercardbf4ad752b3fb3d0e.webp" alt="" />
                                    <img style={{ height: '26px', width: 'auto' }} src="/images/landing/PayPal-logo-white-png-horizontal.png" alt="PayPal" />
                                    <img src="/images/landing/psebb0ce4c4463aa51b.webp" alt="" />
                                    <img src="/images/landing/efecty91f6cb277fa29fea.webp" alt="" />
                                    <img style={{ height: '26px' }} src="https://epayco.com/wp-content/uploads/2023/04/Logo-negro-3.png" alt="" />
                                </div>
                                <p className="small">
                                    Único pago de $791.280 pagado por adelantado. <a rel="noopener noreferrer" target="_blank" href="https://vpixel.co/landingterms">Términos y condiciones disponibles aquí</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="warranty">
                <div className="container">
                    <div className="warranty__content">

                        <div className='warranty__info'>
                            <div className="title">
                                <h2 className="title-gradient">
                                    Tranquilo. Protegemos su inversión con una <span className="color-yellow">garantía total</span>
                                </h2>
                            </div>
                            <img src="/images/landing/warranty.png" alt="Garantía de satisfacción" />

                            <p className="lead">
                                Sin complicaciones ni trámites engorrosos, le ofrecemos un reembolso del 100% de lo que haya pagado si siente que nuestro trabajo no está completamente alineado con sus expectativas o las expectativas que tiene para tu marca o emprendimiento.
                            </p>
                            <p className="small">
                                <strong>Es muy sencillo: </strong>compártanos tus razones, solicite el reembolso y en un promediod de dos a cinco días tendrá el reembolso reflejado en su cuenta. Estos plazos dependen de su banco. VPixel CO Trabaja con Bancolombia por lo que si tiene tu cuenta en otro banco, puede que el reembolso tome un par de días más.
                            </p>
                        </div>

                    </div>
                </div>
            </section >



        </>
    );
};

export default Pricing;