import React from "react";
import SEO from '../../components/SEO/SEO';

const Terms = () => {
    return (
        <>
            <SEO
                title="Terms and Conditions - VPixel CO"
                description="Our terms and conditions for all our services and products."
                ogImage="https://vpixel.co/assets/OG-v2-Final-EN.jpg"
                favicon="https://vpixel.co/assets/favicon-32x32.png"
                appleFavicon="https://vpixel.co/assets/apple-touch-icon.png"
            />

            <div className="terms-container">
                <div className="container">


                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="" data-bs-toggle="tab" data-bs-target="#english" type="button" role="tab" aria-controls="english" aria-selected="false">English</button>
                            <button className="nav-link" id="" data-bs-toggle="tab" data-bs-target="#spanish" type="button" role="tab" aria-controls="spanish" aria-selected="true">Español</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="english" role="tabpanel" aria-labelledby="english" tabindex="0">

                            <div className="title">
                                <h2 className="title-gradient">
                                    Terms and Conditions of <span className="color-yellow">VPixel CO</span> Services
                                </h2>
                            </div>
                            <p><span>These are the standard Terms and Conditions for Website Design and Development,
                                Graphic Design, Domain Name Registration, SEO (Search Engine Optimisation), and Web Hosting. The
                                Customer agrees to these terms stated upon beginning any of the services stated above, or otherwise
                                provided by VPixel CO.</span></p>
                            <h3 className="h3-title color-yellow " id="h.j2rw60eo5r2w"><span>General:</span></h3>
                            <p><span>It is solely the responsibility of the Customer to keep VPixel CO informed of any
                                changes in their contact details. Failure to do so may result in service expiration or the inability to
                                continue rendering services.</span></p>
                            <p><span>An account with VPixel CO is intended for use solely by the Customer associated
                                with the account. Individual account holders are prohibited from selling, reselling, storing, or
                                transferring VPixel CO services to third parties.</span></p>
                            <p><span>VPixel CO reserves the right to refuse its services and/or products to anyone.
                                This includes the right to suspend or cancel a Customer&rsquo;s access to existing services if there is
                                a violation of this Terms Agreement or if VPixel CO, at its absolute discretion, determines that the
                                services have been abused or used inappropriately.</span></p>
                            <p><span>All invoices, quotes, service announcements, and any other notifications will be
                                sent to the Customer via email.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.2pq3k06ncnjb"><span>Cancellations &amp; Refunds:</span></h3>
                            <p><span></span></p>
                            <p><span>All deposits paid are non-refundable.</span></p>
                            <p><span>Fees charged on a pre-pay basis are non-refundable.</span></p>
                            <p><span>Progress payments made during the course of a VPixel CO service are
                                non-refundable.</span></p>
                            <p><span>In the event of a customer violating the VPixel CO terms and conditions
                                agreement, VPixel CO reserves the right to immediately discontinue the service with no refund.</span>
                            </p>
                            <p><span>Refunds may be issued if VPixel CO fails to deliver the described service. Such
                                occurrences are subject to the discretion of VPixel CO.</span></p>
                            <p><span></span></p>
                            <p><span>Upon sign-off on completed design work, prior to website construction, all fees
                                (excluding the bill balance) are due for payment. No cancellations will be accepted for signed-off
                                work.</span></p>
                            <p><span>The balance of an invoice is a percentage value (determined by VPixel CO) of the
                                total bill indicated in the initial quote, which is the final payment upon service completion.</span>
                            </p>
                            <p><span>The limitations, functionality, and/or usability of any VPixel CO software
                                (including, but not limited to; WordPress, WP eCommerce, ShopPlugin, Prestashop) do not justify
                                withholding payment of the website bill balance. If the Customer requires specific functionality, VPixel
                                CO provides services to accommodate such requests for an additional fee.</span></p>
                            <p><span>If the customer&#39;s website or service has been discontinued due to
                                cancellation or expiration, VPixel CO can reinstate the website within a 12-month period for an
                                additional fee of $20 USD to cover reconnection costs.</span></p>
                            <p><span>Any cancellations of recurring services must be notified 30 days in advance.
                                Bills paid in advance are non-refundable.</span></p>
                            <p><span>Please contact VPixel CO for service cancellation. VPixel CO will not accept
                                service cancellations without notice.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.lefwvelwr5ey"><span>Website Design, Development &amp; Support:</span></h3>
                            <p><span>The Customer will receive a website design mock-up via FIGMA Link based on the
                                design brief submitted. The customer may request a reasonable number of changes to the concepts until
                                the design meets satisfaction. Approximately 2-5 revisions are considered normal.</span></p>
                            <p><span>The website, graphics, and any programming code remain the property of VPixel CO
                                until all outstanding accounts are paid in full.</span></p>
                            <p><span>If a logo has been purchased from VPixel CO alongside a website design, the logo
                                must be approved before the website design commences.</span></p>
                            <p><span>VPixel CO&#39;s WordPress websites are self-managed, and we are not responsible
                                for any copy, editing, or updating of the Customer&#39;s website unless otherwise contracted.</span></p>
                            <p><span>All websites and landing pages will be delivered as shells with pages/posts
                                filled with dummy or previously provided text and images. The Customer is solely responsible for
                                inserting copy, products, and/or images, etc.</span></p>
                            <p><span>While the Customer maintains full ownership of the website&rsquo;s content and
                                design (after the balance has been paid), certain elements of the website designs remain the copyright
                                of VPixel CO.</span></p>
                            <p><span>Support, FAQs, and manuals are available free of charge in each user account and
                                on our support center to assist customers in updating their websites.</span></p>
                            <p><span>Two hours of WordPress CMS Training is provided as part of the website design
                                quote. Additional training sessions are billable at $10 USD per hour.</span></p>
                            <p><span>If hosting is purchased through VPixel CO, each website account is allocated the
                                stated bandwidth and disk space, and any additional usage will incur an additional charge. We will
                                monitor the Customer&rsquo;s usage, and if we anticipate that the website may require more
                                bandwidth/disk space, the allocation will be increased automatically. Please refer to our website for
                                allocations and pricing. While we monitor the bandwidth/disk space usage, we do not take responsibility
                                for any website going offline due to exceeding bandwidth or disk space. However, we will strive to
                                return it to its last working state if it goes offline due to bandwidth or disk space issues, provided
                                the Customer reactivates or updates their hosting service.</span></p>
                            <p><span>All VPixel CO websites include a built-in authorship anchor tag that links to our
                                website from the footer of the Customer&rsquo;s website. This link cannot be removed during the contract
                                with VPixel CO.</span></p>
                            <p><span>We provide a warranty for 90 days following the website&#39;s completion date,
                                during which any bugs and defects reported to us will be repaired at no charge. After this warranty
                                period, we will not be liable for any bugs or defects that arise unless otherwise stated under the
                                Website Hosting item 1. Changes, fixes, and bugs (outside the warranty) may be billable.</span></p>
                            <p><span>A manufacturer warranty will be provided for 12 months once the website is live
                                to cover any hosting issues, including but not limited to: server updates, restarts, and patches. We
                                will determine what constitutes a bug resulting from hosting issues covered under this warranty.</span>
                            </p>
                            <p><span>We will strive to fix all issues reported to us within 48 hours of being
                                notified, or upon acceptance of a quote for a fix (whichever is applicable).</span></p>
                            <p><span>We only support web browsers and email clients released within the past 12 months
                                from the website or email newsletter completion date. Any required compatibility beyond this may incur
                                an additional charge.</span></p>
                            <p><span>We are not liable if an email newsletter or HTML-based email does not display
                                properly due to the email client/program/website/application not supporting current standards for email
                                development.</span></p>
                            <p><span></span></p>
                            <p><span>We are not liable for bugs and issues if a completed website is removed from its
                                originally installed server without the Customer&rsquo;s notice to us, unless otherwise stipulated in a
                                previously stated warranty.</span></p>
                            <p><span></span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.mtgsfrf5lr8u"><span>Financial Arrangements for Websites:</span></h3>
                            <p><span>The cost of the VPixel CO Website (including Brochure Websites, Shopping Cart
                                Websites, and Landing Pages) is a fixed price for design and installation. VPixel CO guarantees no
                                ongoing fees unless otherwise agreed upon in a separate contract.</span></p>
                            <p><span>Quoted estimates are valid for 30 days from the date of issue. Our payment
                                schedule is as follows:</span></p>
                            <ul>
                                <li><span>50% of the total project cost as a deposit before work
                                    begins.</span></li>
                                <li><span>The remaining 50% of the total cost will be invoiced either 20 calendar days after the initial payment or upon project completion and testing, marking the start of the 90-day warranty period, whichever occurs first.</span></li>
                            </ul>
                            <p><span>If there are any variations or extensions to the project, or if the Customer
                                wishes to engage VPixel CO on a time and material basis, all work will be charged according to our
                                hourly rates of $30 USD per hour.</span></p>
                            <p><span></span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.n8njn2tt0psa"><span>Graphic Design:</span></h3>
                            <p><span>The deposit for graphic design work is due upon acceptance of the quote. The
                                remaining balance is due when the artwork has been approved, prior to the final delivery of graphical
                                files.</span></p>
                            <p><span>Any additional changes requested after sign-off will be quoted and billed
                                separately.</span></p>
                            <p><span>For logo design services, Customers will receive multiple design concepts via
                                email based on the design brief. Customers can request a reasonable number of changes to their chosen
                                concepts until they are satisfied with the design. Approximately 2-5 rounds of changes are considered
                                normal.</span></p>
                            <p><span>Upon final approval of the design, the Customer will receive the final graphic
                                design files via email or other agreed-upon delivery methods.</span></p>
                            <p><span>VPixel CO provides all artwork in print-ready files for the Customer&rsquo;s use.
                                It is the Customer&#39;s responsibility to verify all content, contact details, email addresses, phone
                                numbers, or any other requested information for accuracy. VPixel CO will not be held legally or
                                otherwise responsible for any errors on the artwork that were not noticed prior to its use, including
                                spelling, grammar, color issues, or typing errors. We recommend obtaining a &quot;Chem Proof&quot; from
                                your printer before finalizing the print run.</span></p>
                            <p><span>All graphic designs created by VPixel CO are the copyright of the Customer.
                                Ownership will be transferred to the Customer upon receipt of full payment.</span></p>
                            <p><span></span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.uf0cvd8z6zot"><span>Domain Registration:</span></h3>
                            <p><span>There is no guarantee made by VPixel CO that the requested domain name(s) are
                                available or can be registered under the requested suffix. We are not liable for any domain name
                                requirements, including registration, renewal, and transfer.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.i51dvvuq4moc"><span>Hosting Services:</span></h3>
                            <p><span>VPixel CO expressly hosts code written only by VPixel CO developers (past or
                                present).</span></p>
                            <p><span>We guarantee the health of WordPress Websites built by VPixel CO for a period of
                                one year while hosted with us, covering only browser updates and styling issues. It is at our discretion
                                to determine which issues are covered by this guarantee. Fixes for these issues are provided at no
                                charge. Any fixes or changes outside these stipulations are billable.</span></p>
                            <p><span>Invoices for Yearly Hosting Services are invoiced for a month in advance and are
                                due within 30 days of issue. Failure to pay the balance may result in a service interruption.</span></p>
                            <p><span>We assume no responsibility for timelines, deletion, mis-delivery, or failure to
                                store your communications, personalization settings, or any other information stored on your hosting
                                service.</span></p>
                            <p><span>VPixel CO is not liable for any content linked or embedded from an external
                                source that may disappear due to server connection interruption. Additionally, we reserve the right to
                                remove any linked or embedded content that breaches copyright or other laws without notice to the
                                Customer.</span></p>
                            <p><span>In the event of server compromise, we will endeavor to restore the
                                Customer&rsquo;s website to its previous state as soon as possible but are not responsible for lost
                                content during restoration.</span></p>
                            <p><span>We are not liable for compromises due to outdated WordPress software, plugins, or
                                browser software. If a compromise occurs due to outdated WordPress software and/or plugins, the Customer
                                will be billed for updating the website installation.</span></p>
                            <p><span>Refusal of WordPress and plugin updates voids all associated warranties and
                                guarantees with Digital Developments.</span></p>
                            <p><span>Customers are not contractually obligated to remain hosting with VPixel CO, and
                                services may be terminated at any time. Bills paid in advance are non-refundable, and all outstanding
                                invoices must be paid in full.</span></p>
                            <p><span>Additional capacity and bandwidth can be purchased for a pre-set fee and apply
                                until the next invoice for renewal or until a month has passed, whichever comes first.</span></p>
                            <p><span>VPixel CO reserves the right to change hosting package prices without notice.
                                Existing hosting services will not be affected by any pricing changes unless the Customer is otherwise
                                notified.</span></p>
                            <p><span>If the Customer requests their website to be placed on another server, a Set-Up
                                Fee may be incurred based on the server type, location, and website complexity.</span></p>
                            <p><span>You are responsible for keeping a copy of any existing website we may replace
                                pursuant to web design services, including all databases and hosted files. We can provide an archival
                                solution for the existing website at our standard commercial rates.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.ys5mg0cyelfr"><span>Transfer:</span></h3>
                            <p><span>This agreement may not be transferred under any circumstances without the written
                                consent of VPixel CO.</span></p>
                            <p><span>VPixel CO websites can be transferred away from our servers at any time, but all
                                warranties become void, and we will no longer be liable for the website or its contents. Domain names
                                will always remain the property of the Customer and are registered under the Customer who ordered them
                                whenever possible.</span></p>
                            <p><span>This Terms Agreement establishes the absolute understanding of all parties
                                involved. Upon the renewal of services covered by this Agreement, any changes or modifications made
                                during the term of service are agreed to by both parties.</span></p>
                            <p><span>This Agreement constitutes the entire understanding of the parties. Any changes
                                or modifications to this Contract are agreed to by both parties upon renewal of services.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.fmpb6oq3y2si"><span>Offers and Promotions:</span></h3>
                            <h4 id="h.1pv14yn703cr"><span>Landing Page, aumente sus ventas en tan solo 7 días (https://vpixel.co/landing)</span></h4>
                            <p><span>Slots:</span></p>
                            <p><span>We have 5 slots available for this promotion. The
                                availability of slots will decrease as clients request and pay for their respective services. If slots
                                are exhausted at the time of your request and payment, we will adjust the necessary resources to
                                accommodate your project.</span></p>
                            <p><span>Delivery Times:</span></p>
                            <p><span>The delivery time for the offered product is between 5 and 12 calendar days to
                                ensure quality work and efficiency. The client must provide materials such as texts and images on time
                                to meet this deadline. If these materials are not available, we offer the option to use Artificial
                                Intelligence (AI) to generate them, but initial material is required as a model.</span></p>
                            <p><span>Materials must be sent to VPixel CO exclusively via email; no materials will be
                                accepted through other means such as WhatsApp.</span></p>
                            <p><span>No additional changes or modifications will be accepted once the Landing Page is
                                about to be delivered. Modifications will be made after its publication.</span></p>
                            <p><span>The client must approve or provide feedback within a reasonable time frame to
                                allow for necessary adjustments and to keep the project within the estimated deadline.</span></p>
                            <p><span>Payments and Refunds:</span></p>
                            <p><span>Payment for this offer must be made in advance at the start of the work, due to
                                the promotional nature of the offered price.</span></p>
                            <p><span>In case of disagreement with advance payment, the following alternatives are
                                available:</span></p>
                            <ol>
                                <li><span>Payment can be made once VPixel CO has completed the
                                    development of the Landing Page and it is ready for publication, provided that no more than 10 days
                                    have passed since the start of the work.</span></li>
                                <li><span>Payment can also be made after 10 calendar days from the
                                    start of the work, whether or not the Landing Page is completed, to avoid indefinite project
                                    extension.</span></li>
                            </ol>
                            <p><span>To request refunds, please send an email to e@vpixel.digital with the
                                corresponding reasons. Refunds will be processed within two business days.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.zap3n93vjoan"><span>Taxes:</span></h3>
                            <p><span>VPixel CO shall not be liable for any taxes or other fees related to sales made
                                by the Customer using our server. The Customer agrees to take full responsibility for all taxes and fees
                                associated with such products sold by them.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.irqtv8bkxbmj"><span>Hardware, Equipment &amp; Software:</span></h3>
                            <p><span>It is assumed that the Customer has the necessary knowledge to maintain the
                                VPixel CO WordPress website. The Customer agrees that it is not the responsibility of VPixel CO to
                                provide this knowledge or maintain the website on behalf of the Customer without the purchase of
                                additional services and the previously defined support.</span></p>
                            <p><span>VPixel CO makes no guarantees, assurances, or warranties regarding the
                                compatibility of the Customer&rsquo;s computer equipment and/or software with any VPixel CO product or
                                service.</span></p>
                            <p><span>It is the Customer&rsquo;s sole responsibility to provide all personal or company
                                details necessary for accessing VPixel CO websites.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.4sneltfmjq8j"><span>Limitations of Liability:</span></h3>
                            <p><span>VPixel CO is not liable to the Customer (whether under contract or otherwise)
                                under the following stipulations:</span></p>
                            <p><span></span></p>
                            <ul>
                                <li><span>There is no express guarantee from any party, affiliate,
                                    agent, merchants, or licensors, etc., that any VPixel CO services or products will be uninterrupted
                                    or error-free.</span></li>
                                <li><span>There is no express guarantee regarding the accuracy,
                                    reliability, or content of any information or service contained in or provided through VPixel CO
                                    servers (including websites) unless otherwise expressly stated in this agreement.</span></li>
                                <li><span>VPixel CO is not responsible for any damage to the
                                    Customer&rsquo;s computer systems or loss of data resulting from the download, use, or distribution
                                    of any material &ndash; digital or otherwise &ndash; from our websites or any affiliates or active
                                    clients.</span></li>
                                <li><span>VPixel CO, including its officers, agents, employees, etc.,
                                    is not liable (including through negligence) for any direct, indirect, incidental, special, or
                                    consequential damages resulting from the use or inability to use our services, or from mistakes,
                                    omissions, interruptions, deletion of files, errors, defects, delays in operation or transmission,
                                    or any failure of performance, whether or not limited to acts of God, communication failure, theft,
                                    destruction, or unauthorized access to our records, programs, servers, or services.</span></li>
                                <li><span>Regardless of the above, the Customer&rsquo;s exclusive
                                    remedies for all damages, losses, and causes of actions, whether in contract, tort (including
                                    negligence), or otherwise, shall not exceed the combined dollar amount paid by the Customer during
                                    the term of this Agreement, plus any reasonable attorney&rsquo;s fees and court costs.</span></li>
                            </ul>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.vsqpbnvoq41n"><span>Disclaimer:</span></h3>
                            <p><span>VPixel CO and its parents, subsidiaries, affiliates, officers, directors,
                                shareholders, employees, and agents shall not be liable, under any circumstances or legal theories
                                whatsoever, for:</span></p>
                            <ul>
                                <li><span>Any loss of business, profits, or goodwill, loss of use or
                                    data, interruption of business, or any indirect, special, incidental, or consequential damages of
                                    any character, even if VPixel CO is aware of the risk of such damages, resulting in any way from the
                                    Customer&rsquo;s use or inability to use the online services or software, or resulting from errors,
                                    defects, omissions, delays in operation or transmission, or any other failure of performance of the
                                    website, servers, or software.</span></li>
                                <li><span>VPixel CO disclaims any warranty of merchantability or
                                    fitness for a particular purpose and will not be responsible for any damages suffered by the
                                    Customer, including loss of data resulting from delays, non-deliveries, or service interruptions by
                                    any cause or errors or omissions of the Customer.</span></li>
                                <li><span>VPixel CO expressly limits its damages to the Customer for
                                    any non-accessibility time or other downtime to the pro-rata monthly or yearly charge during the
                                    service unavailability. VPixel CO specifically denies any responsibility for damages arising as a
                                    consequence of such unavailability.</span></li>
                                <li><span>Use of any information obtained through VPixel CO is at the
                                    Customer&rsquo;s own risk, and VPixel CO specifically denies any responsibility for the accuracy or
                                    quality of information obtained through our services.</span></li>
                                <li><span>VPixel CO cannot be held liable for nor control the actions
                                    of website users, visitors, or linked third parties.</span></li>
                            </ul>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.vpccp2ur2hq"><span></span></h3>
                            <h3 className="h3-title color-yellow " id="h.agkws1r5zay3"><span></span></h3>
                            <h3 className="h3-title color-yellow " id="h.ptl9fkr9rerb"><span>Goodwill:</span></h3>
                            <p><span>This proposal assumes goodwill from both parties regarding:</span></p>
                            <ul>
                                <li><span>Reasonable expectations for achievements within a given
                                    timeframe.</span></li>
                                <li><span>Utilizing resources effectively to achieve optimal
                                    outcomes.</span></li>
                            </ul>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.vbys59478gin"><span>Trademarks &amp; Copyrights:</span></h3>
                            <p><span>The Customer warrants the right to use any applicable trademarks.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.sbtay2jk1dtj"><span>Indemnification:</span></h3>
                            <p><span>The Customer agrees to defend, indemnify, and hold VPixel CO harmless from any
                                demands, liabilities, losses, costs, and claims, including reasonable attorneys&rsquo; fees, asserted
                                against VPixel CO, its agents, customers, servants, officers, and employees, arising from:</span></p>
                            <ul>
                                <li><span>Any services provided or performed, or agreed to be
                                    performed, by the Customer, its agents, employees, or assigns.</span></li>
                                <li><span>Injury to person or property caused by products sold or
                                    distributed in connection with VPixel CO&#39;s server.</span></li>
                                <li><span>Material supplied by the Customer infringing on the
                                    proprietary rights of a third party.</span></li>
                                <li><span>Copyright infringement.</span></li>
                                <li><span>Defective products sold on VPixel CO&#39;s server.</span>
                                </li>
                            </ul>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.yynhkhqsct24"><span>Governing Laws &amp; Severability:</span></h3>
                            <p><span>This agreement shall be governed by the laws of Bogot&aacute;, Colombia. All
                                parties irrevocably submit to the non-exclusive jurisdiction of the courts of Colombia.</span></p>
                            <p><span></span></p>
                            <h3 className="h3-title color-yellow " id="h.vm5k4rtqkvds"><span>Contract Revisions:</span></h3>
                            <p><span>Revisions to this Contract will be applicable to previous Contracts. Revisions
                                will be considered agreed to by the Customer upon renewal of VPixel CO services as specified in Section
                                2: Cancellation &amp; Refund.</span></p>
                            <p><span></span></p>
                            <p><span></span></p>
                            <p><span></span></p>


                        </div>
                        <div className="tab-pane fade" id="spanish" role="tabpanel" aria-labelledby="spanish" tabindex="1">

                            <div>
                                <div className="title">
                                    <h2 className="title-gradient">
                                        Términos y Condiciones de los Servicios de <span className="color-yellow">VPixel CO</span>
                                    </h2>
                                </div>
                                <p><span>Estos son los T&eacute;rminos y Condiciones est&aacute;ndar para Dise&ntilde;o y
                                    Desarrollo de Sitios Web, Dise&ntilde;o Gr&aacute;fico, Registro de Nombres de Dominio, SEO
                                    (Optimizaci&oacute;n de Motores de B&uacute;squeda) y Alojamiento Web. El Cliente acepta estos
                                    t&eacute;rminos al comenzar cualquiera de los servicios mencionados anteriormente, o proporcionados de
                                    otra manera por VPixel CO.</span></p>
                                <h3 className="h3-title color-yellow "><span>General:</span></h3>
                                <p><span>Es exclusivamente responsabilidad del Cliente mantener informado a VPixel CO de
                                    cualquier cambio en sus datos de contacto. La falta de hacerlo puede resultar en la expiraci&oacute;n
                                    del servicio o la imposibilidad de continuar brindando servicios.</span></p>
                                <p><span>Una cuenta con VPixel CO est&aacute; destinada exclusivamente para el uso del
                                    Cliente asociado con la cuenta. Los titulares de cuentas individuales tienen prohibido vender, revender,
                                    almacenar o transferir los servicios de VPixel CO a terceros.</span></p>
                                <p><span>VPixel CO se reserva el derecho de rechazar sus servicios y/o productos a
                                    cualquier persona. Esto incluye el derecho de suspender o cancelar el acceso de un Cliente a los
                                    servicios existentes si hay una violaci&oacute;n de este Acuerdo de T&eacute;rminos o si VPixel CO, a su
                                    absoluta discreci&oacute;n, determina que los servicios han sido abusados o utilizados
                                    inapropiadamente.</span></p>
                                <p><span>Todas las facturas, cotizaciones, anuncios de servicios y cualquier otra
                                    notificaci&oacute;n se enviar&aacute;n al Cliente por correo electr&oacute;nico.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Cancelaciones y Reembolsos:</span></h3>
                                <p><span>Todos los dep&oacute;sitos pagados no son reembolsables.</span></p>
                                <p><span>Las tarifas cobradas en base a prepago no son reembolsables.</span></p>
                                <p><span>Los pagos a cuenta realizados durante el curso de un servicio de VPixel CO no son
                                    reembolsables.</span></p>
                                <p><span>En caso de que un cliente viole el acuerdo de t&eacute;rminos y condiciones de
                                    VPixel CO, VPixel CO se reserva el derecho de interrumpir inmediatamente el servicio sin
                                    reembolso.</span></p>
                                <p><span>Los reembolsos pueden ser emitidos si VPixel CO no entrega el servicio descrito.
                                    Tales casos est&aacute;n sujetos a la discreci&oacute;n de VPixel CO.</span></p>
                                <p><span>Al aprobar el trabajo de dise&ntilde;o completo, antes de la construcci&oacute;n
                                    del sitio web, todas las tarifas (excluyendo el saldo pendiente) deben ser pagadas. No se
                                    aceptar&aacute;n cancelaciones para trabajos aprobados.</span></p>
                                <p><span>El saldo de una factura es un valor porcentual (determinado por VPixel CO) del
                                    total indicado en el presupuesto inicial, que es el pago final al completar el servicio.</span></p>
                                <p><span>Las limitaciones, funcionalidad y/o usabilidad de cualquier software de VPixel CO
                                    (incluyendo, pero no limitado a; WordPress, WP eCommerce, ShopPlugin, Prestashop) no justifican retener
                                    el pago del saldo pendiente de la factura del sitio web. Si el Cliente requiere funcionalidades
                                    espec&iacute;ficas, VPixel CO proporciona servicios para satisfacer tales solicitudes por una tarifa
                                    adicional.</span></p>
                                <p><span>Si el sitio web o servicio del cliente ha sido interrumpido debido a la
                                    cancelaci&oacute;n o vencimiento, VPixel CO puede restablecer el sitio web dentro de un per&iacute;odo
                                    de 12 meses por una tarifa adicional de $20 USD para cubrir los costos de reconexi&oacute;n.</span></p>
                                <p><span>Cualquier cancelaci&oacute;n de servicios recurrentes debe ser notificada con 30
                                    d&iacute;as de anticipaci&oacute;n. Las facturas pagadas por adelantado no son reembolsables.</span></p>
                                <p><span>Por favor, p&oacute;ngase en contacto con VPixel CO para la cancelaci&oacute;n
                                    del servicio. VPixel CO no aceptar&aacute; cancelaciones de servicio sin previo aviso.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Dise&ntilde;o, Desarrollo y Soporte de Sitios Web:</span>
                                </h3>
                                <p><span>El Cliente recibir&aacute; un boceto de dise&ntilde;o del sitio web a
                                    trav&eacute;s del enlace FIGMA basado en el resumen de dise&ntilde;o enviado. El cliente puede solicitar
                                    un n&uacute;mero razonable de cambios en los conceptos hasta que el dise&ntilde;o satisfaga. Se
                                    consideran normales aproximadamente 2-5 revisiones.</span></p>
                                <p><span>El sitio web, los gr&aacute;ficos y cualquier c&oacute;digo de
                                    programaci&oacute;n permanecen propiedad de VPixel CO hasta que todas las cuentas pendientes sean
                                    pagadas en su totalidad.</span></p>
                                <p><span>Si se ha adquirido un logotipo de VPixel CO junto con un dise&ntilde;o de sitio
                                    web, el logotipo debe ser aprobado antes de que comience el dise&ntilde;o del sitio web.</span></p>
                                <p><span>Los sitios web de WordPress de VPixel CO son autogestionados y no somos
                                    responsables de ninguna copia, edici&oacute;n o actualizaci&oacute;n del sitio web del Cliente a menos
                                    que se contrate de otra manera.</span></p>
                                <p><span>Todos los sitios web y p&aacute;ginas de destino se entregar&aacute;n como
                                    estructuras con p&aacute;ginas/publicaciones rellenas con texto e im&aacute;genes ficticias o
                                    proporcionadas previamente. El Cliente es &uacute;nicamente responsable de insertar copia, productos y/o
                                    im&aacute;genes, etc.</span></p>
                                <p><span>Si bien el Cliente mantiene la propiedad total del contenido y dise&ntilde;o del
                                    sitio web (despu&eacute;s de que se haya pagado el saldo), ciertos elementos de los dise&ntilde;os del
                                    sitio web siguen siendo propiedad de VPixel CO.</span></p>
                                <p><span>El soporte, preguntas frecuentes y manuales est&aacute;n disponibles de forma
                                    gratuita en cada cuenta de usuario y en nuestro centro de soporte para ayudar a los clientes a
                                    actualizar sus sitios web.</span></p>
                                <p><span>Se proporcionan dos horas de capacitaci&oacute;n en el CMS de WordPress como
                                    parte del presupuesto de dise&ntilde;o del sitio web. Las sesiones de capacitaci&oacute;n adicionales
                                    tienen un costo de $10 USD por hora.</span></p>
                                <p><span>Si el alojamiento se compra a trav&eacute;s de VPixel CO, cada cuenta de sitio
                                    web tiene asignada la cantidad declarada de ancho de banda y espacio en disco, y cualquier uso adicional
                                    incurrir&aacute; en un cargo adicional. Monitoreamos el uso del Cliente y, si anticipamos que el sitio
                                    web puede requerir m&aacute;s ancho de banda/espacio en disco, la asignaci&oacute;n se aumentar&aacute;
                                    autom&aacute;ticamente. Consulte nuestro sitio web para conocer las asignaciones y precios. Si bien
                                    monitoreamos el uso de ancho de banda/espacio en disco, no nos responsabilizamos si un sitio web se
                                    desconecta debido a exceder el ancho de banda o el espacio en disco. Sin embargo, nos esforzamos por
                                    devolverlo a su &uacute;ltimo estado de funcionamiento si se desconecta debido a problemas de ancho de
                                    banda o espacio en disco, siempre que el Cliente reactive o actualice su servicio de alojamiento.</span>
                                </p>
                                <p><span>Todos los sitios web de VPixel CO incluyen una etiqueta de anclaje de
                                    autor&iacute;a incorporada que enlaza con nuestro sitio web desde el pie de p&aacute;gina del sitio web
                                    del </span><span>Cliente</span><span>. Este enlace no se puede eliminar durante
                                        el contrato con VPixel CO.</span></p>
                                <p><span>Proporcionamos una garant&iacute;a durante 90 d&iacute;as despu&eacute;s de la
                                    fecha de finalizaci&oacute;n del sitio web, durante la cual cualquier error o defecto reportado a
                                    nosotros ser&aacute; reparado sin cargo alguno. Despu&eacute;s de este per&iacute;odo de
                                    garant&iacute;a, no seremos responsables de ning&uacute;n error o defecto que surja a menos que se
                                    indique lo contrario en el punto 1 de Alojamiento de Sitios Web. Cambios, correcciones y errores (fuera
                                    de la garant&iacute;a) pueden tener un costo adicional.</span></p>
                                <p><span>Se proporcionar&aacute; una garant&iacute;a del fabricante durante 12 meses una
                                    vez que el sitio web est&eacute; en vivo para cubrir cualquier problema de alojamiento, incluyendo, pero
                                    no limitado a: actualizaciones del servidor, reinicios y parches. Determinaremos qu&eacute; constituye
                                    un error resultante de problemas de alojamiento cubiertos por esta garant&iacute;a.</span></p>
                                <p><span>Nos esforzaremos por solucionar todos los problemas reportados a nosotros dentro
                                    de las 48 horas posteriores a la notificaci&oacute;n, o al aceptar un presupuesto para una
                                    reparaci&oacute;n (seg&uacute;n corresponda).</span></p>
                                <p><span>Solo admitimos navegadores web y clientes de correo electr&oacute;nico lanzados
                                    en los &uacute;ltimos 12 meses a partir de la fecha de finalizaci&oacute;n del sitio web o del
                                    bolet&iacute;n de correo electr&oacute;nico. Cualquier compatibilidad requerida m&aacute;s all&aacute;
                                    de esto puede incurrir en un cargo adicional.</span></p>
                                <p><span>No nos hacemos responsables si un bolet&iacute;n de correo electr&oacute;nico o
                                    un correo electr&oacute;nico basado en HTML no se muestra correctamente debido a que el cliente de
                                    correo electr&oacute;nico/programa/sitio web/aplicaci&oacute;n no admite los est&aacute;ndares actuales
                                    para el desarrollo de correo electr&oacute;nico.</span></p>
                                <p><span>No nos hacemos responsables de errores y problemas si un sitio web completado es
                                    eliminado de su servidor originalmente instalado sin el aviso del Cliente a nosotros, a menos que se
                                    estipule lo contrario en una garant&iacute;a previamente indicada.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Arreglos Financieros para Sitios Web:</span></h3>
                                <p><span>El costo del Sitio Web de VPixel CO (incluyendo Sitios Web de Folletos, Sitios
                                    Web de Carritos de Compras y P&aacute;ginas de Aterrizaje) es un precio fijo por dise&ntilde;o e
                                    instalaci&oacute;n. VPixel CO garantiza que no habr&aacute; tarifas recurrentes a menos que se acuerde
                                    lo contrario en un contrato separado.</span></p>
                                <p><span>Los presupuestos cotizados son v&aacute;lidos por 30 d&iacute;as a partir de la
                                    fecha de emisi&oacute;n. Nuestro cronograma de pagos es el siguiente:</span></p>
                                <ul>
                                    <li><span>El 50% del costo total del proyecto como dep&oacute;sito
                                        antes de que comience el trabajo.</span></li>
                                    <li><span>
                                        El 50% restante del costo total se facturará ya sea 20 días calendario después del pago inicial o al completar el proyecto y las pruebas, lo que marque el inicio del período de garantía de 90 días, lo que ocurra primero.
                                    </span></li>
                                </ul>
                                <p><span>Si hay alguna variaci&oacute;n o extensi&oacute;n del proyecto, o si el Cliente
                                    desea contratar a VPixel CO en base a tiempo y material, todo el trabajo se cobrar&aacute; seg&uacute;n
                                    nuestras tarifas horarias de $30 USD por hora.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Dise&ntilde;o Gr&aacute;fico:</span></h3>
                                <p><span>El dep&oacute;sito para el trabajo de dise&ntilde;o gr&aacute;fico vence al
                                    aceptarse el presupuesto. El saldo restante se debe cuando el arte ha sido aprobado, antes de la entrega
                                    final de los archivos gr&aacute;ficos.</span></p>
                                <p><span>Cualquier cambio adicional solicitado despu&eacute;s de la aprobaci&oacute;n
                                    ser&aacute; cotizado y facturado por separado.</span></p>
                                <p><span>Para servicios de dise&ntilde;o de logotipos, los Clientes recibir&aacute;n
                                    m&uacute;ltiples conceptos de dise&ntilde;o por correo electr&oacute;nico basados en el resumen de
                                    dise&ntilde;o. Los Clientes pueden solicitar un n&uacute;mero razonable de cambios a sus conceptos
                                    elegidos hasta que est&eacute;n satisfechos con el dise&ntilde;o. Se consideran normales aproximadamente
                                    2-5 rondas de cambios.</span></p>
                                <p><span>Al aprobar definitivamente el dise&ntilde;o, el Cliente recibir&aacute; los
                                    archivos finales de dise&ntilde;o gr&aacute;fico por correo electr&oacute;nico u otros m&eacute;todos de
                                    entrega acordados.</span></p>
                                <p><span>VPixel CO proporciona todo el arte en archivos listos para imprimir para el uso
                                    del Cliente. Es responsabilidad del Cliente verificar todo el contenido, detalles de contacto,
                                    direcciones de correo electr&oacute;nico, n&uacute;meros de tel&eacute;fono o cualquier otra
                                    informaci&oacute;n solicitada por su exactitud. VPixel CO no ser&aacute; legal o de otra manera
                                    responsable de ning&uacute;n error en el arte que no se haya notado antes de su uso, incluyendo errores
                                    de ortograf&iacute;a, gram&aacute;tica, problemas de color o errores tipogr&aacute;ficos. Recomendamos
                                    obtener una &quot;Prueba de Qu&iacute;mico&quot; de su impresora antes de finalizar la tirada de
                                    impresi&oacute;n.</span></p>
                                <p><span>Todos los dise&ntilde;os gr&aacute;ficos creados por VPixel CO son propiedad del
                                    Cliente. La propiedad ser&aacute; transferida al Cliente al recibir el pago completo.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Registro de Dominios:</span></h3>
                                <p><span>VPixel CO no garantiza que el(los) nombre(s) de dominio solicitado(s)
                                    est&eacute;n disponibles o puedan ser registrados bajo el sufijo solicitado. No somos responsables de
                                    ning&uacute;n requisito relacionado con el nombre de dominio, incluyendo registro, renovaci&oacute;n y
                                    transferencia.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Servicios de Alojamiento:</span></h3>
                                <p><span>VPixel CO aloja expresamente el c&oacute;digo escrito &uacute;nicamente por los
                                    desarrolladores de VPixel CO (pasados o presentes).</span></p>
                                <p><span>Garantizamos la salud de los Sitios Web de WordPress construidos por VPixel CO
                                    por un per&iacute;odo de un a&ntilde;o mientras est&eacute;n alojados con nosotros, cubriendo solo
                                    actualizaciones del navegador y problemas de estilo. Est&aacute; a nuestra discreci&oacute;n determinar
                                    qu&eacute; problemas est&aacute;n cubiertos por esta garant&iacute;a. Las correcciones para estos
                                    problemas se proporcionan sin cargo alguno. Cualquier correcci&oacute;n o cambio fuera de estas
                                    estipulaciones tendr&aacute; un costo adicional.</span></p>
                                <p><span>Las facturas de los Servicios de Alojamiento Anual se facturan por un mes por
                                    adelantado y deben pagarse dentro de los 30 d&iacute;as posteriores a la emisi&oacute;n. El no pago del
                                    saldo puede resultar en la interrupci&oacute;n del servicio.</span></p>
                                <p><span>No asumimos responsabilidad por plazos, eliminaci&oacute;n, entrega
                                    err&oacute;nea o fallo en el almacenamiento de sus comunicaciones, configuraciones de
                                    personalizaci&oacute;n o cualquier otra informaci&oacute;n almacenada en su servicio de
                                    alojamiento.</span></p>
                                <p><span>VPixel CO no es responsable de ning&uacute;n contenido vinculado o incrustado
                                    desde una fuente externa que pueda desaparecer debido a una interrupci&oacute;n de la conexi&oacute;n
                                    del servidor. Adem&aacute;s, nos reservamos el derecho de eliminar cualquier contenido vinculado o
                                    incrustado que infrinja derechos de autor u otras leyes sin previo aviso al Cliente.</span></p>
                                <p><span>En caso de compromiso del servidor, nos esforzaremos por restaurar el sitio web
                                    del Cliente a su estado anterior lo antes posible, pero no somos responsables de contenido perdido
                                    durante la restauraci&oacute;n.</span></p>
                                <p><span>No somos responsables de compromisos debido a software de WordPress, complementos
                                    o software de navegadores desactualizados. Si se produce un compromiso debido a software de WordPress
                                    y/o complementos desactualizados, el Cliente ser&aacute; facturado por actualizar la instalaci&oacute;n
                                    del sitio web.</span></p>
                                <p><span>La negativa a las actualizaciones de WordPress y complementos anula todas las
                                    garant&iacute;as asociadas con Desarrollos Digitales.</span></p>
                                <p><span>Los Clientes no est&aacute;n obligados contractualmente a permanecer alojados con
                                    VPixel CO, y los servicios pueden ser terminados en cualquier momento. Las facturas pagadas por
                                    adelantado no son reembolsables y todas las facturas pendientes deben pagarse en su totalidad.</span>
                                </p>
                                <p><span>Se pueden comprar capacidad y ancho de banda adicionales por una tarifa
                                    preestablecida y se aplican hasta la pr&oacute;xima factura de renovaci&oacute;n o hasta que haya pasado
                                    un mes, lo que ocurra primero.</span></p>
                                <p><span>VPixel CO se reserva el derecho de cambiar los precios del paquete de alojamiento
                                    sin previo aviso. Los servicios de alojamiento existentes no se ver&aacute;n afectados por ning&uacute;n
                                    cambio de precios a menos que se notifique de otra manera al Cliente.</span></p>
                                <p><span>Si el Cliente solicita que su sitio web se coloque en otro servidor, puede
                                    incurrirse en una tarifa de configuraci&oacute;n seg&uacute;n el tipo de servidor, la ubicaci&oacute;n y
                                    la complejidad del sitio web.</span></p>
                                <p><span>Usted es responsable de mantener una copia de cualquier sitio web existente que
                                    podamos reemplazar de acuerdo con los servicios de dise&ntilde;o web, incluidas todas las bases de datos
                                    y archivos alojados. Podemos proporcionar una soluci&oacute;n de archivo para el sitio web existente a
                                    nuestras tarifas comerciales est&aacute;ndar.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Transferencia:</span></h3>
                                <p><span>Este acuerdo no puede ser transferido bajo ninguna circunstancia sin el
                                    consentimiento por escrito de VPixel CO.</span></p>
                                <p><span>Los sitios web de VPixel CO pueden ser transferidos lejos de nuestros servidores
                                    en cualquier momento, pero todas las garant&iacute;as quedan anuladas, y ya no seremos responsables del
                                    sitio web o su contenido. Los nombres de dominio siempre permanecer&aacute;n propiedad del Cliente y se
                                    registrar&aacute;n bajo el Cliente que los orden&oacute; siempre que sea posible.</span></p>
                                <p><span>Este Acuerdo de T&eacute;rminos establece el entendimiento absoluto de todas las
                                    partes involucradas. Al renovar los servicios cubiertos por este Acuerdo, cualquier cambio o
                                    modificaci&oacute;n realizada durante el plazo del servicio es aceptada por ambas partes.</span></p>
                                <p><span>Este Acuerdo constituye el entendimiento completo de las partes. Cualquier cambio
                                    o modificaci&oacute;n a este Contrato es aceptado por ambas partes al renovar los servicios.</span></p>
                                <h3 className="h3-title color-yellow "><span></span></h3>
                                <h3 className="h3-title color-yellow "><span>Ofertas y Promociones:</span></h3>
                                <h4><span>Landing Page, aumente sus ventas en tan solo 7 días (https://vpixel.co/landing) </span></h4>
                                <p><span>Cupos: </span><span>Disponemos de 5 cupos de trabajo para esta
                                    promoci&oacute;n. La disponibilidad de cupos se reducir&aacute; conforme
                                    los clientes soliciten y paguen sus servicios respectivos. En caso de agotarse los cupos al momento de
                                    su solicitud y pago, ajustaremos los recursos necesarios para priorizar su proyecto.</span></p>
                                <p><span>Tiempos de Entrega: </span><span>El plazo de entrega del producto
                                    ofrecido es de 5 a 12 d&iacute;as calendario para garantizar un trabajo de calidad y eficiencia. El
                                    cliente debe proporcionar los materiales como textos e im&aacute;genes a tiempo para cumplir con este
                                    plazo. En caso de no contar con estos materiales, ofrecemos la opci&oacute;n de utilizar Inteligencia
                                    Artificial (IA) para generarlos, pero se requiere un material inicial como modelo.</span></p>
                                <p><span>Los materiales deben ser enviados a VPixel CO exclusivamente por correo
                                    electr&oacute;nico; no se aceptar&aacute;n materiales por otros medios como WhatsApp.</span></p>
                                <p><span>No se aceptar&aacute;n cambios o modificaciones adicionales una vez que la
                                    Landing Page est&eacute; por ser entregada. Las modificaciones ser&aacute;n realizadas posteriormente a
                                    la publicaci&oacute;n de la misma.</span></p>
                                <p><span>El cliente debe aprobar o emitir sus comentarios (Feedback) en un tiempo prudente
                                    para permitir los ajustes necesarios y mantener el proyecto dentro del plazo estimado.</span></p>
                                <p><span>Pagos y Devoluciones:</span></p>
                                <p><span>El pago de esta oferta debe realizarse por adelantado al momento de iniciar el
                                    trabajo, debido al car&aacute;cter promocional del precio ofrecido.</span></p>
                                <p><span>En caso de desacuerdo con el pago por adelantado, se ofrecen las siguientes
                                    alternativas:</span></p>
                                <ol start="1">
                                    <li><span>El pago puede realizarse una vez que VPixel CO haya
                                        finalizado el desarrollo de la Landing Page y est&eacute; lista para su publicaci&oacute;n, siempre
                                        que no hayan transcurrido m&aacute;s de 10 d&iacute;as desde el inicio del trabajo.</span></li>
                                    <li><span>El pago tambi&eacute;n puede efectuarse despu&eacute;s de 10
                                        d&iacute;as calendario desde el inicio del trabajo, independientemente de si la Landing Page
                                        est&aacute; finalizada, para evitar la prolongaci&oacute;n indefinida del proyecto.</span></li>
                                </ol>
                                <p><span>Para solicitar devoluciones, por favor env&iacute;e un correo electr&oacute;nico
                                    a e@vpixel.digital con las razones correspondientes. Las devoluciones se procesar&aacute;n en un plazo
                                    de dos d&iacute;as h&aacute;biles.</span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Impuestos:</span></h3>
                                <p><span>VPixel CO no ser&aacute; responsable de ning&uacute;n impuesto u otros cargos
                                    relacionados con las ventas realizadas por el Cliente utilizando nuestro servidor. El Cliente acepta
                                    asumir toda la responsabilidad por todos los impuestos y tarifas asociadas con los productos vendidos
                                    por ellos.</span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Hardware, Equipos y Software:</span></h3>
                                <p><span>Se asume que el Cliente tiene el conocimiento necesario para mantener el sitio
                                    web de WordPress de VPixel CO. El Cliente acepta que no es responsabilidad de VPixel CO proporcionar
                                    este conocimiento o mantener el sitio web en nombre del Cliente sin la compra de servicios adicionales y
                                    el soporte previamente definido.</span></p>
                                <p><span>VPixel CO no ofrece garant&iacute;as, seguridades o garant&iacute;as con respecto
                                    a la compatibilidad del equipo inform&aacute;tico y/o software del Cliente con ning&uacute;n producto o
                                    servicio de VPixel CO.</span></p>
                                <p><span>Es responsabilidad exclusiva del Cliente proporcionar todos los detalles
                                    personales o de la empresa necesarios para acceder a los sitios web de VPixel CO.</span></p>
                                <p><span></span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow "><span>Limitaciones de Responsabilidad:</span></h3>
                                <p><span>VPixel CO no es responsable ante el Cliente (ya sea bajo contrato u otro) bajo
                                    las siguientes estipulaciones:</span></p>
                                <ul>
                                    <li><span>No hay garant&iacute;a expresa de ninguna parte, afiliado,
                                        agente, comerciante o licenciatario, etc., de que ning&uacute;n servicio o producto de VPixel CO
                                        ser&aacute; ininterrumpido o libre de errores.</span></li>
                                    <li><span>No hay garant&iacute;a expresa con respecto a la
                                        precisi&oacute;n, confiabilidad o contenido de ninguna informaci&oacute;n o servicio contenidos o
                                        proporcionados a trav&eacute;s de los servidores de VPixel CO (incluidos los sitios web), a menos
                                        que se indique expresamente lo contrario en este acuerdo.</span></li>
                                    <li><span>VPixel CO no es responsable de ning&uacute;n da&ntilde;o a
                                        los sistemas inform&aacute;ticos del Cliente o p&eacute;rdida de datos resultante de la descarga,
                                        uso o distribuci&oacute;n de cualquier material -digital o de otro tipo- de nuestros sitios web o de
                                        cualquier afiliado o cliente activo.</span></li>
                                    <li><span>VPixel CO, incluidos sus directores, agentes, empleados,
                                        etc., no son responsables (incluyendo por negligencia) de ning&uacute;n da&ntilde;o directo,
                                        indirecto, incidental, especial o consecuencial que resulte del uso o la imposibilidad de usar
                                        nuestros servicios, o de errores, omisiones, interrupciones, eliminaci&oacute;n de archivos,
                                        errores, defectos, retrasos en la operaci&oacute;n o transmisi&oacute;n, o cualquier fallo de
                                        rendimiento, ya sea limitado o no a actos de Dios, fallo de comunicaci&oacute;n, robo,
                                        destrucci&oacute;n o acceso no autorizado a nuestros registros, programas, servidores o
                                        servicios.</span></li>
                                    <li><span>Independientemente de lo anterior, los remedios exclusivos
                                        del Cliente para todos los da&ntilde;os, p&eacute;rdidas y causas de acci&oacute;n, ya sea en
                                        contrato, agravio (incluyendo negligencia) u otro, no exceder&aacute;n la cantidad en d&oacute;lares
                                        pagada por el Cliente durante el plazo de este Acuerdo, m&aacute;s cualquier honorario razonable de
                                        abogado y costos judiciales.</span></li>
                                </ul>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow " id="h.kcrccpubu96o"><span>Descargo de responsabilidad:</span></h3>
                                <p><span>VPixel CO y sus empresas matrices, subsidiarias, afiliadas, funcionarios,
                                    directores, accionistas, empleados y agentes no ser&aacute;n responsables, bajo ninguna circunstancia o
                                    teor&iacute;as legales, por:</span></p>
                                <ul>
                                    <li><span>Cualquier p&eacute;rdida de negocios, beneficios o buena
                                        voluntad, p&eacute;rdida de uso o datos, interrupci&oacute;n de negocios o cualquier da&ntilde;o
                                        indirecto, especial, incidental o consecuencial de cualquier tipo, incluso si VPixel CO es
                                        consciente del riesgo de tales da&ntilde;os, resultantes de cualquier manera del uso del Cliente o
                                        la incapacidad de utilizar los servicios en l&iacute;nea o el software, o resultantes de errores,
                                        defectos, omisiones, retrasos en la operaci&oacute;n o transmisi&oacute;n, o cualquier otro fallo de
                                        rendimiento del sitio web, servidores o software.</span></li>
                                    <li><span>VPixel CO renuncia a cualquier garant&iacute;a de
                                        comerciabilidad o aptitud para un prop&oacute;sito particular y no ser&aacute; responsable de
                                        ning&uacute;n da&ntilde;o sufrido por el Cliente, incluida la p&eacute;rdida de datos resultante de
                                        retrasos, no entregas o interrupciones del servicio por cualquier causa o errores u omisiones del
                                        Cliente.</span></li>
                                    <li><span>VPixel CO limita expresamente sus da&ntilde;os al Cliente por
                                        cualquier tiempo de no accesibilidad u otros tiempos de inactividad al cargo mensual o anual
                                        prorrateado durante la indisponibilidad del servicio. VPixel CO niega espec&iacute;ficamente
                                        cualquier responsabilidad por da&ntilde;os que surjan como consecuencia de dicha
                                        indisponibilidad.</span></li>
                                    <li><span>El uso de cualquier informaci&oacute;n obtenida a
                                        trav&eacute;s de VPixel CO es bajo el propio riesgo del Cliente, y VPixel CO niega
                                        espec&iacute;ficamente cualquier responsabilidad por la precisi&oacute;n o calidad de la
                                        informaci&oacute;n obtenida a trav&eacute;s de nuestros servicios.</span></li>
                                    <li><span>VPixel CO no puede ser considerado responsable ni controlar
                                        las acciones de los usuarios del sitio web, visitantes o terceros vinculados.</span></li>
                                </ul>
                                <h3 className="h3-title color-yellow " id="h.p7nhwm32a49u"><span>Buena Voluntad:</span></h3>
                                <p><span>Esta propuesta asume la buena voluntad de ambas partes con respecto a:</span></p>
                                <p><span>Expectativas razonables para logros dentro de un marco de tiempo dado.</span></p>
                                <p><span>Utilizaci&oacute;n efectiva de recursos para lograr resultados
                                    &oacute;ptimos.</span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow " id="h.egjxkxy6gks6"><span>Marcas Registradas y Derechos de Autor:</span></h3>
                                <p><span>El Cliente garantiza el derecho de utilizar cualquier marca registrada
                                    aplicable.</span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow " id="h.g4ot2fvu8fea"><span>Indemnizaci&oacute;n:</span></h3>
                                <p><span>El Cliente acepta defender, indemnizar y eximir de responsabilidad a VPixel CO de
                                    cualquier demanda, responsabilidad, p&eacute;rdida, costo y reclamo, incluidos los honorarios razonables
                                    de abogados, interpuestos contra VPixel CO, sus agentes, clientes, servidores, funcionarios y empleados,
                                    que surjan de:</span></p>
                                <ul>
                                    <li><span>Cualquier servicio proporcionado o realizado, o acordado ser
                                        realizado, por el Cliente, sus agentes, empleados o asignados.</span></li>
                                    <li><span>Lesiones a personas o da&ntilde;os a la propiedad causados
                                        por productos vendidos o distribuidos en conexi&oacute;n con el servidor de VPixel CO.</span></li>
                                    <li><span>Material proporcionado por el Cliente que infrinja los
                                        derechos de propiedad de un tercero.</span></li>
                                    <li><span>Infracci&oacute;n de derechos de autor.</span></li>
                                    <li><span>Productos defectuosos vendidos en el servidor de VPixel
                                        CO.</span></li>
                                </ul>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow " id="h.hw31uw3ve0za"><span>Leyes Aplicables y Divisibilidad:</span></h3>
                                <p><span>Este acuerdo se regir&aacute; por las leyes de Bogot&aacute;, Colombia. Todas las
                                    partes se someten irrevocablemente a la jurisdicci&oacute;n no exclusiva de los tribunales de
                                    Colombia.</span></p>
                                <p><span></span></p>
                                <h3 className="h3-title color-yellow " id="h.eqtt1fx5kdld"><span>Revisiones del Contrato:</span></h3>
                                <p><span>Las revisiones a este Contrato ser&aacute;n aplicables a Contratos anteriores.
                                    Las revisiones se considerar&aacute;n aceptadas por el Cliente al renovar los servicios de VPixel CO
                                    como se especifica en la Secci&oacute;n 2: Cancelaciones y Reembolsos.</span></p>
                                <p><span></span></p>
                            </div>


                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Terms;
