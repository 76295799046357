import React from "react";

import Logos from '../../components/Logos/Logos';
import Rating from '../../components/Rating/Rating';
import ProjectsList from '../../components/Projectfeed/Projectfeed';
import InfoCards from '../../components/InfoCards/InfoCards';
import Pricing from '../../components/Pricing/Pricing';
import FaqsEs from '../../components/FaqsEs/FaqsEs';
import SEO from '../../components/SEO/SEO';
import constants from '../../constants.json';

const Landing = () => {
    const discountValue = 10;

    return (
        <div className="landing">
            <SEO
                title="¡Sitios web para emprendedores en descuento! - VPixel CO"
                description="Solicite su Sitio Web con un 10% de descuento y aumente sus ventas en sólo 7 días y comienza a construir tu éxito online hoy."
                ogImage="/images/landing/OG-v2-Final-EN.jpg"
                favicon="https://vpixel.co/assets/favicon-32x32.png"
                appleFavicon="https://vpixel.co/assets/apple-touch-icon.png"
            />
            <section id='about' className="about-us section nostatic" style={{ backgroundImage: 'url(/images/landing/Frame461.jpg)' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <header className="title flex-column d-flex">
                                <Rating language="es" />
                                <h1 className="h-title title-gradient">
                                    Solicite su Landing Page con un {discountValue}% de descuento y <span className="color-yellow">aumente sus ventas en sólo 7 días <img className="person-img" src="/images/people/happy.webp" alt="Happy!" /></span>
                                </h1>
                            </header>
                        </div>
                        <div className="col-12 d-flex flex-column align-items-center">
                            <p className='about-us__description'>
                                Las empresas líderes no solo tienen presencia online, sino que ofrecen sus servicios o productos el mercado digital con Landing Pages de alto impacto. Aprovecha nuestro 10% de descuento y comienza a construir tu éxito online hoy mismo.
                            </p>
                            <div className="actions">
                                <a href={constants.site.contactLink} aria-label="¡Cotice hoy!" className="button">¡Cotice hoy mismo!</a>
                                <a href="#info" aria-label="Más información" className="button button--bordered">Más información</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="logos-section">
                <div className="container">
                    <Logos />
                </div>
            </section>

            <section id="info" className="info">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <header className="title">
                                <h2 className="title-gradient">
                                    Ahora bien, ¿Qué es una Landing Page y <span className="color-yellow">cómo puede beneficiar su negocio? <img src="/images/people/question.webp" alt="" className="person-img" /></span>
                                </h2>
                                <p className="lead">
                                    Una Landing Page es el tipo de página web perfecta para destacar y convertir visitantes en clientes potenciales sin esfuerzo. Aquí le explicaremos los beneficios para su negocio:
                                </p>
                            </header>
                        </div>
                    </div>
                </div>
            </section>

            <section className="fix">
                <div className="container">
                    <div className="fix-container">
                        <div className="cards">
                            <InfoCards />
                        </div>
                    </div>
                </div>
            </section>

            <section id='portfolio' className="projects">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <header className="title">
                                <h2 className="title-gradient">
                                    Ejemplos y Resultados: <span className="color-yellow">¿Cómo quedan estas Landing Pages? <img src="/images/people/girl-4.webp" alt="" className="person-img" /></span>
                                </h2>
                                <p className='lead'>
                                    Explore algunos ejemplos destacados de Landing Pages y Páginas Completas que hemos realizado a lo largo de estos años para clientes de todo el mundo.
                                </p>
                            </header>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="projects__grid">
                                <div className="text">
                                    <div className="info d-flex flex-column">
                                        <h3>Resultados <span className="color-yellow">comprobables</span></h3>
                                        <p>
                                            Creamos cada Landing Page con una dedicación excepcional, entregándonos por completo al proceso con un compromiso inquebrantable y una visión que trasciende lo convencional.
                                        </p>
                                        <Rating language="es" />
                                        <a target="_blank" rel="noopener noreferrer" href={constants.site.contactLink} data-service="landing" className="button">
                                            Solicite una cotización
                                        </a>
                                    </div>
                                </div>
                                <ProjectsList language="es" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="data">
                <div className="container">
                    <div className="row row-vh">
                        <div className="col-12 col-md-8">
                            <header className="title">
                                <h2 className="title-gradient">
                                    Posicione su negocio y aumente sus ventas <img src="/images/people/girl.webp" alt="" className="person-img" /> de forma <span className="color-yellow">sencilla, rápida y efectiva.</span>
                                </h2>
                            </header>
                        </div>
                    </div>

                    <div className="row data-row">
                        <div className="col-12 col-md">
                            <article className="data__item">
                                <h3 className="color-yellow">78%</h3>
                                <p>
                                    De los usuarios que visitan una Landing page específica realizan la acción deseada ya sea comprar un producto, descargar un ebook o suscribirse a un boletín informativo.
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-md">
                            <article className="data__item">
                                <h3 className="color-yellow">200%</h3>
                                <p>
                                    Es el aumento del ROI (Retorno de inversión) que perciben en promedio los negocios que manejan una Landing Page para promocionar su servicio o producto.
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-md">
                            <article className="data__item">
                                <h3 className="color-yellow">50%</h3>
                                <p>
                                    De más clics e interacciones pues al tener una llamada a la acción convincente, las Landing pages animan a más usuarios a realizar la acción deseada.
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Pricing discountValue={discountValue} />

            <FaqsEs />

            <section id='contact' className="call">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <header className="title">
                                <span className="subtitle">¿Convencido? Demos el siguiente paso</span>
                                <h2 className="title-gradient">Empecemos su proyecto digital <span className="color-yellow">hoy mismo</span></h2>
                            </header>
                            <div className="actions">
                                <p className="small"><strong className="color-yellow">
                                    Hablemos por WhatsApp. </strong> Hable directamente con nuestro equipo de expertos y resuelva todas sus dudas</p>
                                <a href={constants.site.contactLink} aria-label="Hablemos ¡Ya mismo!" className="button">+57 318 851 3737</a>
                                <Rating language="es" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Landing;
