import React from 'react';

const News = () => {
    return (
        <>


            <section className="news">
                <div className="container">
                    <div className="news__content">
                        <p>
                            <span className="color-yellow">Heads up! &nbsp;</span>
                            For October 🎃 we only have 2 spots left for new projects.
                            <a href="https://cal.com/vpixel" className="button button--bordered">Grab yours here <span className="arrow"></span>&rarr;</a>
                        </p>
                        <p className="small">Fixed costs. No lock-ins. Clear deliverables.</p>
                    </div>
                </div>
            </section>

        </>
    );
};

export default News;
