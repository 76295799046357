import React from 'react'
import './Faqs.css';
import bg from './cta-faqs-v2.jpg';
import constants from '../../constants.json';
const faqs = [
    {
        title: 'Who are VPixel CO?',
        content: '<p>VPixel CO is an independent, boutique web design and development studio led by two world-class design engineers with over 10 years of combined experience. We serve clients worldwide, including:</p><ul><li>Startups</li><li>Early-stage companies</li><li>Large corporations</li><li>NGOs</li></ul><p>For these clients, we design and develop digital products such as landing pages, WordPress websites, and online stores. Our team consists of:</p><ul><li>4 designers</li><li>5 web developers</li><li>2 SEO/SEM specialists</li></ul><p>who combine their expertise to create exceptional digital products.</p>'
    },
    {
        title: 'How much do we charge for a website?',
        content: '<p>Our Web Design and Development services typically range from $230 to $1200 USD. However, the final cost depends on the specific needs and requirements of each project. Here is a rough estimate based on the type of product:</p><ul><li>Landing Pages (single informational page): from $230 USD</li><li>Manageable corporate websites: from $600 USD</li><li>Complete online stores: from $900 USD</li></ul><p>Contact us today, let\'s chat, and we can provide you with a quote tailored to your project\'s needs.</p>'
    },
    {
        title: 'Why do we charge those prices?',
        content: '<p>Creating a website involves a process that requires time, planning, and thorough research. Unlike other agencies, we do NOT use pre-made templates. We build your website from scratch, ensuring a unique and personalized design for your business.</p><p>Acquiring a website from us shouldn\'t be seen as just a business expense; we believe that a website is a sales tool designed to generate revenue for the client.</p>'
    },
    {
        title: 'Do you offer a payment plan?',
        content: '<p>Yes, we divide the payment into two parts:</p><ol><li>The first 50% is typically made once the client is ready to proceed.</li><li>The remaining 50% is due 20 calendar days after the first payment or upon project delivery, whichever comes first.</li></ol><p>Read more about this in our <a href="https://docs.google.com/document/d/1NQDkBFVFb5D01xc8RXRLiPRw2AKPZWY-BNMEM2gLKgQ/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.</p>'
    },
    {
        title: 'Do you offer a discount for non-profit organizations?',
        content: '<p>Yes, we offer a 20% discount for non-profit organizations. Please contact us to discuss your project and how we can help you achieve your goals.</p>'
    },
    {
        title: 'How much time do we need to develop a website?',
        content: '<p>Depending on the project, a website can take anywhere from 2 to 6 weeks to complete. Here\'s a breakdown:</p><ul><li>Landing pages: approximately 2 weeks</li><li>Marketing and corporate websites: 3 to 4 weeks</li><li>E-commerce stores: 4 to 6 weeks</li></ul><p>We will provide a precise timeline once we have the project details.</p>'
    },
    {
        title: 'What do you need to start a project?',
        content: '<p>The requirements vary depending on the type of website the client is looking for. A detailed discussion will take place during the discovery call before starting the collaboration. Depending on the agreed package, the following elements might be necessary:</p><ul><li>Photos of the client\'s team</li><li>Images of the client\'s workplace</li><li>Website content such as text, PDFs, etc.</li><li>Profiles of the client\'s staff</li><li>Any videos the client wants to include on the website</li><li>Login details for the client\'s current website</li></ul><p>Some of this information might already be available on the client\'s current website, in which case, it can be easily transferred to the new site.</p>'
    },
    {
        title: 'What if I dont like the design?',
        content: '<p>We offer a <span style="font-weight: bold; text-transform: uppercase;" class="color-yellow">risk-free guarantee</span>. Our process includes:</p><ol><li>Designing a mockup of your homepage before building your new website</li><li>Allowing you to see our initial designs and evaluate the progress</li><li>Providing an opportunity for you to give feedback</li></ol><p>If you are not satisfied, there is no obligation to proceed. We will refund your payment as long as only the homepage design has been completed.</p>'
    }
]

const Faqs = () => {
    return (

        <div style={{ backgroundImage: `url(${bg})` }} className="faqs static">
            <div id="faqs" style={{ position: 'relative', top: '-100px' }}></div>
            <div className="container">


                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <div>
                                <span className="subtitle">Is better when we have it all clear</span>
                                <h2 className="title-gradient">Frequently Asked <span className="color-yellow">Questions</span></h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="faqs__container grid">
                            <div className="faqs__content">
                                <div className="accordion" id="accordionFaqs">
                                    {faqs.map((faq, index) => (
                                        <div className="accordion-item faq-item box" key={index}>
                                            <h2 className="accordion-header" id={`header-${index}`}>
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#content-${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`content-${index}`}
                                                >
                                                    {faq.title}
                                                </button>
                                            </h2>
                                            <div
                                                id={`content-${index}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`header-${index}`}
                                                data-bs-parent="#accordionFaqs"
                                            >
                                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faq.content }} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="faqs__call d-flex flex-column">
                                <img className="img-fluid" src="/images/people/erick-2.webp" alt="" />

                                <h3 className='title-gradient'>Need more help?</h3>
                                <p>We're ready to assist with any additional question you could have, we also offer a FREE consulting call if you only need orientation for your new project.</p>
                                <a
                                    className="button button--bordered"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={constants.site.contactLink}
                                >
                                    Book a call
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Faqs;
