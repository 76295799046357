import React from "react";

import { motion } from "framer-motion";
import "./marquee.css";

const marqueeVariants = {
  animate: {
    x: [0, -1035],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 15,
        ease: "linear",
      },
    },
  },
};
const Marquee = () => {
  return (
    <>
      <div className="marquee__bottom">
        {/* 3. Using framer motion */}
        <motion.div
          className="track"
          variants={marqueeVariants}
          animate="animate"
        >
          <h1>
            Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials. Testimonials.
          </h1>
        </motion.div>
      </div>
    </>
  );
};
export default Marquee;