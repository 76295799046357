import React from "react";
import SEO from '../../components/SEO/SEO';

const Privacy = () => {
    return (
        <>
            <SEO
                title="Privacy Policy - VPixel CO"
                description="Our privacy policy for all our services and products."
                ogImage="https://vpixel.co/assets/OG-v2-Final-EN.jpg"
                favicon="https://vpixel.co/assets/favicon-32x32.png"
                appleFavicon="https://vpixel.co/assets/apple-touch-icon.png"
            />

            <div className="terms-container">
                <div className="container">


                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="" data-bs-toggle="tab" data-bs-target="#english" type="button" role="tab" aria-controls="english" aria-selected="false">English</button>
                            <button className="nav-link" id="" data-bs-toggle="tab" data-bs-target="#spanish" type="button" role="tab" aria-controls="spanish" aria-selected="true">Español</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="english" role="tabpanel" aria-labelledby="english" tabindex="0">

                            <div className="title">
                                <h2 className="title-gradient">
                                    Privacy Policy of <span className="color-yellow">VPixel CO</span>
                                </h2>
                            </div>

                            <p>This Privacy Policy describes how VPixel.digital ("we," "us," or "our") collects, uses, and discloses your personal information when you use our website or any service we provide (the "Service").</p>

                            <h3 className="h3-title color-yellow">Information We Collect</h3>
                            <p>We may collect the following information about you:</p>
                            <ul>
                                <li>Personal Information: We may collect personal information that you provide to us such as your name and email address if you contact us through a form on our website.</li>
                                <li>Log Data: We may collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</li>
                            </ul>
                            <h3 className="h3-title color-yellow">Use of Information</h3>
                            <p>We may use the information we collect to:</p>
                            <ul>
                                <li>Respond to your inquiries and requests.</li>
                                <li>Improve our Service.</li>
                                <li>Send you information about our company and services.</li>
                            </ul>



                            <h3 className="h3-title color-yellow">Disclosure of Information</h3>
                            <p>We may disclose your information to third-party vendors and service providers who help us operate our Service and website (e.g., email marketing services).</p>
                            <p>We will not share your personal information with any other third parties without your consent.</p>

                            <h3 className="h3-title color-yellow">Data Security</h3>
                            <p> We take reasonable steps to protect the information you provide to us from loss, misuse, unauthorized access, disclosure, alteration, and destruction. However, no internet transmission or electronic storage is ever 100% secure. Therefore, we cannot guarantee absolute security.</p>

                            <h3 className="h3-title color-yellow">Children's Privacy</h3>
                            Our Service does not address anyone under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from a child under 13, we will take steps to remove that information from our servers.

                            <h3 className="h3-title color-yellow">Your Rights</h3>
                            <p>You have certain rights with respect to your personal information. You may request:</p>
                            <ul>
                                <li>To access, correct, or delete your personal information</li>
                                <li>To object to the processing of your personal information</li>
                            </ul>
                            <p>You can contact us to exercise any of these rights.</p>

                            <h3 className="h3-title color-yellow">Changes to this Privacy Policy</h3>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>

                            <h3 className="h3-title color-yellow">Contact Us</h3>
                            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@vpixel.co">hello@vpixel.co</a> – Carrera 8 #170-52 Bogotá D.C Colombia.</p>


                        </div>
                        <div className="tab-pane fade" id="spanish" role="tabpanel" aria-labelledby="spanish" tabindex="1">

                            <div>
                                <div className="title">
                                    <h2 className="title-gradient">
                                        Política de Privacidad de <span className="color-yellow">VPixel CO</span>
                                    </h2>
                                </div>

                                <h3 className="h3-title color-yellow">Información que Recopilamos</h3>
                                <p>Podemos recopilar la siguiente información sobre usted:</p>
                                <ul>
                                    <li>Información Personal: Podemos recopilar información personal que usted nos proporcione, como su nombre y dirección de correo electrónico, si se comunica con nosotros a través de un formulario en nuestro sitio web.</li>
                                    <li>Datos de Registro: Podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio ("Datos de Registro"). Estos Datos de Registro pueden incluir información como la dirección de Protocolo de Internet (IP) de su computadora, tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y fecha de su visita, el tiempo que pasa en esas páginas y otras estadísticas.</li>
                                </ul>

                                <h3 className="h3-title color-yellow">Uso de la Información</h3>
                                <p>Podemos usar la información que recopilamos para:</p>
                                <ul>
                                    <li>Responder a sus consultas y solicitudes.</li>
                                    <li>Mejorar nuestro Servicio.</li>
                                    <li>Enviarle información sobre nuestra empresa y servicios.</li>
                                </ul>

                                <h3 className="h3-title color-yellow">Divulgación de la Información</h3>
                                <p>Podemos divulgar su información a proveedores de terceros y prestadores de servicios que nos ayuden a operar nuestro Servicio y sitio web (por ejemplo, servicios de marketing por correo electrónico).</p>
                                <p>No compartiremos su información personal con ningún otro tercero sin su consentimiento.</p>

                                <h3 className="h3-title color-yellow">Seguridad de los Datos</h3>
                                Tomamos medidas razonables para proteger la información que nos proporciona contra pérdidas, mal uso, acceso no autorizado, divulgación, alteración y destrucción. Sin embargo, ninguna transmisión por Internet o almacenamiento electrónico es 100% seguro. Por lo tanto, no podemos garantizar una seguridad absoluta.

                                <h3 className="h3-title color-yellow">Privacidad de los Niños</h3>
                                <p>Nuestro Servicio no está dirigido a personas menores de 13 años. No recopilamos intencionadamente información personal de niños menores de 13 años. Si usted es padre, madre o tutor y sabe que su hijo nos ha proporcionado información personal, contáctenos. Si nos damos cuenta de que hemos recopilado información personal de un niño menor de 13 años, tomaremos medidas para eliminar esa información de nuestros servidores.</p>

                                <h3 className="h3-title color-yellow">Sus Derechos</h3>
                                <p>Usted tiene ciertos derechos con respecto a su información personal. Puede solicitar:</p>
                                <ul>
                                    <li>Acceder, corregir o eliminar su información personal.</li>
                                    <li>Oponerse al procesamiento de su información personal.</li>
                                    <li>Puede contactarnos para ejercer cualquiera de estos derechos.</li>
                                </ul>

                                <h3 className="h3-title color-yellow">Cambios en esta Política de Privacidad</h3>
                                <p> Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en nuestro sitio web.</p>

                                <h3 className="h3-title color-yellow">Contáctenos</h3>
                                <p>Si tiene alguna pregunta sobre esta Política de Privacidad, contáctenos en <a href="mailto:hello@vpixel.co">hello@vpixel.co</a> - Carrera 8 #170-52 Bogotá D.C Colombia.</p>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacy;
